import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Work a weakness for :20`}</p>
    <p>{`then,`}</p>
    <p>{`1/2 Murph `}<em parentName="p">{`(not partitioned…do 50 pullups, then 100 pushups, then 150
squats between 1/2 mile runs)`}</em></p>
    <p>{`1/2 Mile Run`}</p>
    <p>{`50 Pullups`}</p>
    <p>{`100 Pushups`}</p>
    <p>{`150 Squats`}</p>
    <p>{`1/2 Mile Run`}</p>
    <p>{`For time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      